<script lang="ts" setup>
import VbListLoginSearchFilterEdit from "@/components/crud/VbListLoginSearchFilterEdit.vue"
import { useLoginSearchFilter, type LoginSearchFilterExt } from "@/composables/useLoginSearchFilter"
import type { LoginSearchFilterConfiguration } from "@/pages/App/Staff/config"
import { injectMandatory, type ListState } from "@/vf"
import { type ModuleConfiguration } from "@/vf/module-configuration"
import { computed, inject, reactive, unref } from "vue"

const config = unref(
    injectMandatory<ModuleConfiguration>(
        "module-config",
        "VfListSearch must be used inside an element that provides module-config like VfEdit or VfShow.",
    ),
)
const listState = injectMandatory<ListState>(
    "list-state",
    "VfListSearch must be used inside an element that provides list-state like VfList.",
)
const loginSearchFilterConfiguration = inject<LoginSearchFilterConfiguration | undefined>(
    "login-search-filter-config",
    undefined,
)

const listId = computed(() => loginSearchFilterConfiguration?.id ?? config.list?.id())
const loginSearchFilter = useLoginSearchFilter()
loginSearchFilter.init(listId.value, reactive(listState))

function saveFilter(name: string, listId: string) {
    if (name && listId) {
        const newFilter: LoginSearchFilterExt = {
            listId: listId,
            name: name,
            data: listState.filter,
        }
        loginSearchFilter.addFilter(newFilter)
    }
}
</script>

<template>
    <div class="open-row" style="overflow: hidden">
        <div class="open-row-container">
            <div class="d-flex flex-row flex-wrap">
                <VbListLoginSearchFilterEdit @save-filter="saveFilter"></VbListLoginSearchFilterEdit>
            </div>
        </div>
    </div>
</template>
