<script setup lang="ts">
import { createModuleConfiguration } from "./config"
import { ref } from "vue"
import VbEdit from "@/components/crud/VbEdit.vue"

const [config] = createModuleConfiguration()
const item = ref({})
</script>

<template>
    <VbEdit v-bind="{ item, config }"></VbEdit>
</template>
