<script lang="ts" setup>
import { useLoginSearchFilter } from "@/composables/useLoginSearchFilter"
import type { FilterFieldGetter, LoginSearchFilterConfiguration } from "@/pages/App/Staff/config"
import type { Entity, ModuleConfiguration } from "@/vf"
import { injectMandatory } from "@/vf"
import type { ListState } from "@/vf/components/crud/list/types"
import type { ListFilterItem } from "@/vf/module-configuration/types"
import { truncate } from "@sentry/utils"
import { computed, inject, ref, unref } from "vue"
import { Multiselect as VueMultiselect } from "vue-multiselect"

const props = defineProps<{
    field: Entity
}>()

const items = ref<ListFilterItem[]>(props.field.items ?? [])
const config = unref(
    injectMandatory<ModuleConfiguration>(
        "module-config",
        "VfListSearch must be used inside an element that provides module-config like VfEdit or VfShow.",
    ),
)

const filterFieldsGetter = inject<FilterFieldGetter>("filter-fields-getter")

const loginSearchFilterConfiguration = inject<LoginSearchFilterConfiguration | undefined>(
    "login-search-filter-config",
    undefined,
)
const listId = computed(() => loginSearchFilterConfiguration?.id ?? config.list?.id())
const loginSearchFilter = loginSearchFilterConfiguration?.enabled ? useLoginSearchFilter() : undefined
loginSearchFilter?.init(listId.value)

if (filterFieldsGetter && filterFieldsGetter[props.field.field]) {
    filterFieldsGetter[props.field.field]().then((response: ListFilterItem[]) => {
        items.value = response
    })
}

const listState = injectMandatory<ListState>(
    "list-state",
    "VfListSearch must be used inside an element that provides list-state like VfList.",
)

const emit = defineEmits<{
    (e: "search"): void
}>()

const listStateFilter2vueMultiselect = computed({
    get: () => {
        if (listState?.filter && listState?.filter[props.field.field]) {
            return Object.entries(listState.filter[props.field.field]).map(([, listStateValue]) => {
                const item = items.value.find(({ value }) => value === listStateValue)
                return {
                    value: listStateValue,
                    name: item?.name ?? listStateValue,
                }
            })
        }
        return []
    },
    set: value => {
        if (listState && !listState?.filter) {
            listState.filter = {}
        }
        if (listState && !listState?.filter[props.field.field]) {
            listState.filter[props.field.field] = []
        }
        if (listState?.filter && listState?.filter[props.field.field]) {
            listState.filter[props.field.field] = value.map(item => item.value)
        }
    },
})
</script>

<template>
    <template v-if="field.withIcon">
        <div class="form-control-with-icon d-inline-flex ml-4">
            <i class="fas" :class="field.withIcon"></i>
            <template v-if="field.multiSelect">
                <VueMultiselect
                    v-model="listStateFilter2vueMultiselect"
                    :options="items"
                    :placeholder="$t(field.name)"
                    label="name"
                    track-by="value"
                    :multiple="true"
                >
                    <template #selection="{ values }">
                        <span class="multiselect__placeholder" v-if="values.length > 0">
                            {{
                                truncate(
                                    values
                                        .map(value => {
                                            return value.name
                                        })
                                        .join(","),
                                    12,
                                )
                            }}
                        </span>
                        <span class="badge rounded-pill badge-primary" v-if="values.length > 0">
                            {{ values.length }}
                        </span>
                    </template>
                </VueMultiselect>
            </template>
            <template v-else>
                <select class="form-control" @change="emit('search')" v-model="listState.filter[field.field]">
                    <option v-for="item in items" :value="item.value" :key="item.value + item.name">
                        {{ $t(item.name) }}
                    </option>
                    <option :value="undefined">{{ $t(field.name) }}</option>
                </select>
            </template>
        </div>
    </template>
    <template v-else>
        <select
            class="form-control form-inline w-auto"
            v-model="listState.filter[field.field]"
            @change="emit('search')"
        >
            <option :value="undefined">[ {{ $t(field.name) }} ]</option>
            <option v-for="item in items" :value="item.value" :key="item.value + item.name">
                {{ $t(item.name) }}
            </option>
        </select>
    </template>
</template>
