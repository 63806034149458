<script lang="ts" setup>
import VbListSearchFilter from "@/components/crud/VbListSearchFilter.vue"
import type { ModuleConfiguration } from "@/vf"
import { injectMandatory } from "@/vf"
import type { ListState } from "@/vf/components/crud/list/types"

const config = injectMandatory<ModuleConfiguration>(
    "module-config",
    "VfListSearch must be used inside an element that provides module-config like VfEdit or VfShow.",
)
const listState = injectMandatory<ListState>(
    "list-state",
    "VfListSearch must be used inside an element that provides list-state like VfList.",
)

const emit = defineEmits<{
    (e: "search"): void
}>()
</script>

<template>
    <div class="search">
        <form @submit.prevent="emit('search')">
            <div class="d-flex">
                <div class="search-icon">
                    <i class="fas fa-fw fa-search"></i>
                </div>
                <input
                    type="search"
                    class="form-inline search-field flex-grow-1"
                    v-model="listState.searchValue"
                    :placeholder="$t('crud:list.search')"
                />
                <template v-for="field in config.list.filterFields" :key="field.name">
                    <VbListSearchFilter :field="field"></VbListSearchFilter>
                </template>
            </div>
        </form>
    </div>
</template>
