import type { LoginSearchFilter } from "@/model/app/login-search-filter"
import type { UserGroup } from "@/model/app/user-group"
import { useHttpClient, type ListState } from "@/vf"
import type { AxiosResponse } from "axios"
import { defineStore } from "pinia"
import { ref, watch, type Ref } from "vue"

export type LoginSearchFilterList = {
    [key: string]: {
        listState?: ListState
        filters: LoginSearchFilterExt[]
    }
}

export interface LoginSearchFilterExt extends LoginSearchFilter {
    active?: boolean
}

export const useLoginSearchFilter = defineStore("login-search-filter", () => {
    const http = useHttpClient()

    const lists: Ref<LoginSearchFilterList> = ref({})

    function init(listId: string, extListState?: ListState | any) {
        if (listId in lists.value) {
            if (extListState) {
                setFiltersInactive(listId)
                setListState(listId, extListState)
            }
        } else {
            loadFilters(listId).then(() => {
                if (extListState) {
                    setListState(listId, extListState)
                }
            })
        }
    }

    function setListState(listId: string, listState: ListState) {
        lists.value[listId].listState = listState
        addListStateFilterWatcher(listId)
    }

    function addListStateFilterWatcher(listId: string) {
        watch(
            getListState(listId).filter,
            () => {
                setFiltersInactive(listId)
            },
            { once: true },
        )
    }

    async function loadFilters(listId: string, force: boolean = false) {
        if (listId in lists.value && !force) {
            return
        }
        http.get<LoginSearchFilterExt[]>("/login/search-filter/?listId=" + encodeURIComponent(listId)).then(
            (response: AxiosResponse<LoginSearchFilterExt[]>) => {
                setFilters(listId, response.data)
            },
        )
    }

    function setFilters(listId: string, filters: LoginSearchFilterExt[]) {
        if (!(listId in lists.value)) {
            lists.value[listId] = {
                filters: filters,
            }
        } else {
            lists.value[listId].filters = filters
        }
    }

    function reloadFilters(listId: string | undefined) {
        if (listId) {
            loadFilters(listId, true)
        }
    }

    function loadFilter(filter: LoginSearchFilterExt) {
        if (!filter.listId) {
            throw new Error("Missing list id")
        }
        if (!lists.value[filter.listId].listState) {
            throw new Error("Missing list state")
        }
        if (filter.active) {
            setFiltersInactive(filter.listId)
            getListState(filter.listId).filter = {}
        } else {
            setFilterActive(filter)
            getListState(filter.listId).filter = filter.data
        }
        addListStateFilterWatcher(filter.listId)
    }

    function getFilters(listId: string): LoginSearchFilterExt[] | null {
        if (!(listId in lists.value)) {
            loadFilters(listId).then(() => getFilters(listId))
            return null
        }
        return lists.value[listId].filters
    }

    function getListState(listId: string): ListState {
        const listState = lists.value[listId].listState
        if (!listState) {
            throw new Error("Missing list state")
        }
        return listState
    }

    async function addFilter(filter: LoginSearchFilterExt) {
        if (!filter.listId) {
            throw new Error("Missing list id")
        }
        if (!filter.name) {
            throw new Error("Missing name")
        }
        http.post("/login/search-filter/", {
            listId: filter.listId,
            name: filter.name,
            data: filter.data,
        }).then(() => reloadFilters(filter.listId))
    }

    async function updateFilter(filter: LoginSearchFilterExt) {
        if (!filter.id) {
            throw new Error("No ID provided")
        }
        if (!filter.name) {
            throw new Error("Missing name")
        }
        http.put(`/login/search-filter/${filter.id}`, {
            name: filter.name,
        }).then(() => reloadFilters(filter.listId))
    }

    async function removeFilter(filter: LoginSearchFilterExt | undefined) {
        if (!filter) {
            throw new Error("No Filter provided")
        }
        if (!filter.id) {
            throw new Error("No Filter ID provided")
        }
        http.delete(`/login/search-filter/${filter.id}`).then(() => reloadFilters(filter.listId))
    }

    function setFiltersInactive(listId: string) {
        Object.entries(lists.value[listId].filters).forEach(([, filter]) => {
            filter.active = false
        })
    }

    function setFilterActive(filter: LoginSearchFilterExt) {
        if (!filter.listId) {
            throw new Error("No List ID provided")
        }
        setFiltersInactive(filter.listId)
        filter.active = true
    }

    function findShallowCopy(
        filter: LoginSearchFilterExt,
        shallowCopyTarget: string,
    ): LoginSearchFilterExt | undefined {
        if (!filter.id) {
            throw new Error("No ID provided")
        }
        return filter.shallowCopies.find((copy: LoginSearchFilterExt) => copy.listId == shallowCopyTarget)
    }

    function isExposed(
        filter: LoginSearchFilterExt,
        shallowCopyTargets?: {
            name: string
            id: string
        }[],
    ): boolean {
        if (filter.exposed) {
            return true
        }
        if (shallowCopyTargets) {
            shallowCopyTargets.forEach(({ id }) => {
                const shallowCopy = findShallowCopy(filter, id)
                if (shallowCopy?.exposed) {
                    return true
                }
            })
        }
        return false
    }

    async function exposeLoginSearchFilter(
        filter: LoginSearchFilter,
        exposed: boolean,
        exposedToUserGroup?: UserGroup,
        listId?: string,
        shallowCopy?: LoginSearchFilter,
    ) {
        if (listId == null) {
            http.put("/login/search-filter/" + filter.id, {
                exposed: exposed ? 1 : 0,
                exposedToUserGroup: exposedToUserGroup?.id || null,
            }).then(() => reloadFilters(filter.listId))
        } else {
            if (shallowCopy == null) {
                http.post("/login/search-filter/", {
                    listId: listId,
                    shallowCopyOrigin: filter.id,
                    exposed: exposed ? 1 : 0,
                    exposedToUserGroup: exposedToUserGroup?.id || null,
                }).then(() => reloadFilters(filter.listId))
            } else {
                http.put("/login/search-filter/" + shallowCopy.id, {
                    exposed: exposed ? 1 : 0,
                    exposedToUserGroup: exposedToUserGroup?.id || null,
                }).then(() => reloadFilters(filter.listId))
            }
        }
    }

    return {
        init,
        loadFilter,
        getFilters,
        addFilter,
        updateFilter,
        removeFilter,
        findShallowCopy,
        isExposed,
        exposeLoginSearchFilter,
        setFiltersInactive,
        setFilterActive,
    }
})
