<script lang="ts" setup>
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import VarsityInlineConfirmButton from "@/components/VarsityInlineConfirmButton.vue"
import { useVarsityAppConfig } from "@/composables"
import { createInlineConfirmCollection } from "@/composables/createInlineConfirm"
import { useLoginSearchFilter, type LoginSearchFilterExt } from "@/composables/useLoginSearchFilter"
import type { LoginSearchFilterConfiguration } from "@/pages/App/Staff/config"
import { VfDropdown, injectMandatory, useHttpClient, type ListResponse, type ListState } from "@/vf"
import { type ModuleConfiguration } from "@/vf/module-configuration"
import { computed, inject, reactive, ref, unref, type Ref } from "vue"

const { appConfig } = useVarsityAppConfig()

const emit = defineEmits<{
    (e: "save-filter", name: string, listId: string): void
}>()

const config = unref(
    injectMandatory<ModuleConfiguration>(
        "module-config",
        "VfListSearch must be used inside an element that provides module-config like VfEdit or VfShow.",
    ),
)
const listState = injectMandatory<ListState>(
    "list-state",
    "VfListSearch must be used inside an element that provides list-state like VfList.",
)

const http = useHttpClient()
const isAdmin = appConfig.value.canManageLoginSearchFilter === true
const userGroups = isAdmin ? (await http.get<ListResponse>("/user-group")).data.list : []

const loginSearchFilterConfiguration = inject<LoginSearchFilterConfiguration | undefined>(
    "login-search-filter-config",
    undefined,
)
const listId = computed(() => loginSearchFilterConfiguration?.id ?? config.list?.id())
const loginSearchFilter = useLoginSearchFilter()
loginSearchFilter.init(listId.value, reactive(listState))

const newName: Ref<string> = ref("")
const editNameFilterId: Ref<string | undefined> = ref(undefined)
const editNames: Ref<{ [key: string]: string }> = ref({})

function saveFilter() {
    if (newName.value) {
        emit("save-filter", newName.value, listId.value)
    }
}

function updateFilterName(filter: LoginSearchFilterExt) {
    if (!filter.id) {
        throw new Error("No ID provided")
    }
    filter.name = editNames.value[filter.id]
    loginSearchFilter.updateFilter(filter)
    editNameFilterId.value = undefined
}

function editName(filter: LoginSearchFilterExt) {
    if (!filter.id) {
        throw new Error("No ID provided")
    }
    if (!filter.name) {
        throw new Error("No Name provided")
    }
    editNames.value[filter.id] = filter.name
    editNameFilterId.value = filter.id
}

const deleteLoginSearchFilterConfirm = createInlineConfirmCollection((filterId, filter) => ({
    async action() {
        if (!filter) {
            throw new Error("No filter found")
        }
        await loginSearchFilter.removeFilter(filter)
    },
}))
</script>

<template>
    <div class="search-filter mt-4 row">
        <div class="col-4">
            <h2>{{ $t("@tasks:issue.project_filter.save_filter") }}</h2>
            <p>{{ $t("@tasks:issue.project_filter.save_filter.info") }}</p>
            <div class="vb-input-with-button">
                <input type="text" class="form-control" v-model="newName" />
                <button type="button" class="" @click="saveFilter" :disabled="!newName">
                    <i class="fa fa-fw fa-save"></i>
                </button>
            </div>
        </div>
        <div :class="{ 'col-3': !isAdmin, 'col-4': isAdmin }">
            <h2>{{ $t("@tasks:issue.project_filter.my_filters") }}</h2>
            <ul class="list-unstyled mt-2">
                <template v-for="filter in loginSearchFilter.getFilters(listId)" :key="filter.id">
                    <li v-if="filter.login?.id === appConfig.login.id">
                        <div class="d-flex align-items-center">
                            <div class="mr-auto" v-if="editNameFilterId !== filter.id">
                                {{ filter.name }}
                            </div>
                            <div
                                class="mr-auto w-100 vb-input-with-button"
                                style="margin-bottom: 7px; padding-right: 2px"
                                v-if="editNameFilterId === filter.id"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="editNames[filter.id]"
                                    v-if="filter.id"
                                />
                                <button type="button" class="btn-sm" @click="updateFilterName(filter)">
                                    <i class="fa fa-fw fa-save"></i>
                                </button>
                            </div>
                            <button
                                type="button"
                                class="btn btn-primary btn-shape-skewed btn-sm"
                                style="height: 33.5px"
                                @click="editName(filter)"
                                v-if="editNameFilterId !== filter.id"
                            >
                                <i class="fa fa-fw fa-edit"></i>
                            </button>
                            <VarsityInlineConfirmButton
                                class="btn btn-danger btn-shape-skewed btn-sm"
                                style="height: 33.5px"
                                :controller="deleteLoginSearchFilterConfirm(filter.id, filter)"
                                v-if="filter.id"
                            >
                                <i class="fas fa-fw fa-trash-alt"></i>
                            </VarsityInlineConfirmButton>
                            <div v-if="isAdmin">
                                <VfDropdown>
                                    <template #default="{ toggleDropdown }">
                                        <button
                                            class="btn btn-primary btn-shape-skewed btn-sm"
                                            :class="{
                                                'btn-success': loginSearchFilter.isExposed(
                                                    filter,
                                                    loginSearchFilterConfiguration?.shallowCopyTargets,
                                                ),
                                            }"
                                            type="button"
                                            @click="toggleDropdown"
                                        >
                                            <i class="fa fa-fw fa-share-square"></i>
                                        </button>
                                    </template>

                                    <template #menu="{ closeDropdown }">
                                        <div class="dropdown-menu">
                                            <div class="row">
                                                <div class="col">
                                                    <h6 class="dropdown-header mt-0">
                                                        {{ $t("@app:login.loginSearchFilter.forThisPage") }}
                                                    </h6>
                                                    <div class="dropdown-divider"></div>
                                                    <a
                                                        class="dropdown-item"
                                                        :class="{ active: !filter.exposed }"
                                                        @click="
                                                            closeDropdown(
                                                                loginSearchFilter.exposeLoginSearchFilter(
                                                                    filter,
                                                                    false,
                                                                ),
                                                            )
                                                        "
                                                    >
                                                        {{ $t("@app:login.loginSearchFilter.notExposed") }}
                                                    </a>
                                                    <a
                                                        class="dropdown-item"
                                                        :class="{
                                                            active: filter.exposed && !filter.exposedToUserGroup,
                                                        }"
                                                        @click="
                                                            closeDropdown(
                                                                loginSearchFilter.exposeLoginSearchFilter(
                                                                    filter,
                                                                    true,
                                                                    undefined,
                                                                ),
                                                            )
                                                        "
                                                    >
                                                        {{ $t("@app:login.loginSearchFilter.forEverybody") }}
                                                    </a>
                                                    <h6 class="dropdown-header">
                                                        {{ $t("@app:login.loginSearchFilter.forUserGroup") }}
                                                    </h6>
                                                    <a
                                                        class="dropdown-item"
                                                        :class="{
                                                            active:
                                                                filter.exposed &&
                                                                filter.exposedToUserGroup?.id === userGroup.id,
                                                        }"
                                                        v-for="userGroup in userGroups"
                                                        :key="userGroup.id"
                                                        @click="
                                                            closeDropdown(
                                                                loginSearchFilter.exposeLoginSearchFilter(
                                                                    filter,
                                                                    true,
                                                                    userGroup,
                                                                ),
                                                            )
                                                        "
                                                    >
                                                        {{ userGroup.name }}
                                                    </a>
                                                </div>
                                                <div
                                                    class="col"
                                                    v-for="shallowCopyTarget in loginSearchFilterConfiguration?.shallowCopyTargets"
                                                    :key="shallowCopyTarget.id"
                                                >
                                                    <h6 class="dropdown-header mt-0">my-profile</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <a
                                                        class="dropdown-item"
                                                        :class="{
                                                            active: !loginSearchFilter.findShallowCopy(
                                                                filter,
                                                                shallowCopyTarget.id,
                                                            ),
                                                        }"
                                                        @click="
                                                            closeDropdown(
                                                                loginSearchFilter.removeFilter(
                                                                    loginSearchFilter.findShallowCopy(
                                                                        filter,
                                                                        shallowCopyTarget.id,
                                                                    ),
                                                                ),
                                                            )
                                                        "
                                                    >
                                                        {{ $t("@app:login.loginSearchFilter.notExposed") }}
                                                    </a>
                                                    <a
                                                        class="dropdown-item"
                                                        :class="{
                                                            active:
                                                                loginSearchFilter.findShallowCopy(
                                                                    filter,
                                                                    shallowCopyTarget.id,
                                                                ) &&
                                                                !loginSearchFilter.findShallowCopy(
                                                                    filter,
                                                                    shallowCopyTarget.id,
                                                                )?.exposedToUserGroup,
                                                        }"
                                                        @click="
                                                            closeDropdown(
                                                                loginSearchFilter.exposeLoginSearchFilter(
                                                                    filter,
                                                                    true,
                                                                    undefined,
                                                                    shallowCopyTarget.id,
                                                                    loginSearchFilter.findShallowCopy(
                                                                        filter,
                                                                        shallowCopyTarget.id,
                                                                    ),
                                                                ),
                                                            )
                                                        "
                                                    >
                                                        {{ $t("@app:login.loginSearchFilter.forEverybody") }}
                                                    </a>
                                                    <h6 class="dropdown-header">
                                                        {{ $t("@app:login.loginSearchFilter.forUserGroup") }}
                                                    </h6>
                                                    <a
                                                        class="dropdown-item"
                                                        :class="{
                                                            active:
                                                                loginSearchFilter.findShallowCopy(
                                                                    filter,
                                                                    shallowCopyTarget.id,
                                                                )?.exposed &&
                                                                loginSearchFilter.findShallowCopy(
                                                                    filter,
                                                                    shallowCopyTarget.id,
                                                                )?.exposedToUserGroup?.id === userGroup.id,
                                                        }"
                                                        v-for="userGroup in userGroups"
                                                        :key="userGroup.id"
                                                        @click="
                                                            closeDropdown(
                                                                loginSearchFilter.exposeLoginSearchFilter(
                                                                    filter,
                                                                    true,
                                                                    userGroup,
                                                                    shallowCopyTarget.id,
                                                                    loginSearchFilter.findShallowCopy(
                                                                        filter,
                                                                        shallowCopyTarget.id,
                                                                    ),
                                                                ),
                                                            )
                                                        "
                                                    >
                                                        {{ userGroup.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </VfDropdown>
                            </div>
                        </div>
                        <div>
                            <VarsityInlineConfirm
                                :controller="deleteLoginSearchFilterConfirm(filter.id, filter)"
                                v-if="filter.id"
                            >
                                <template #confirmation>
                                    {{ $t("@tasks:issue.project_filter.delete_confirmation") }}
                                </template>
                                <template #success>
                                    {{ $t("@tasks:issue.project_filter.delete_success") }}
                                </template>
                            </VarsityInlineConfirm>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
