import { type Issue } from "@/model/tasks/issue"
import type { LoginSearchFilterConfiguration } from "@/pages/App/Staff/config"
import {
    $datetime,
    normalizeModuleConfiguration,
    useHttpClient,
    type ModuleConfiguration,
    type ModuleConfigurationOptions,
} from "@/vf"
import { h, ref, type Ref } from "vue"
import { useI18n } from "vue-i18n"
import IssueNew from "./new.vue"
import IssueShow from "./show.vue"

export function createModuleConfiguration(
    filterOnlyMessages?: Ref<boolean>,
): [ModuleConfiguration, LoginSearchFilterConfiguration] {
    const i18n = useI18n()
    const $config: ModuleConfigurationOptions = {}
    $config.routePrefix = () => "@Tasks.Issue"
    $config.readableName = {
        plural: "@tasks:issue.issues",
        singular: "@tasks:issue.issue",
    }
    const loginSearchFilter: LoginSearchFilterConfiguration = {
        enabled: true,
        shallowCopyTargets: [
            {
                name: "my-profile",
                id: "/my-varsity/my-profile/my-communication-tasks/issue",
            },
        ],
    }
    $config.list = {
        fields: [
            {
                name: "newIcon",
                title: "",
                type: "vnode",
                getter: item =>
                    item["unreadMessagesCount"] > 0
                        ? h("i", { class: "fa-solid fa-envelope-open-text" })
                        : h("i", { style: "width: 1rem" }),
            },
            {
                name: "starred",
                title: "",
                type: "vnode",
                sortField: "a.starred",
                getter: i => {
                    if (i.starred) {
                        return h("i", {
                            class: "fas fa-star starred active",
                            onClick: async event => {
                                event.stopPropagation()
                                i.starred = (
                                    await useHttpClient().post($config.apiBase?.() + "/" + i.id + "/starred", {
                                        starred: false,
                                    })
                                ).data
                            },
                        })
                    } else {
                        return h("i", {
                            class: "far fa-star starred inactive",
                            onClick: async event => {
                                event.stopPropagation()
                                i.starred = (
                                    await useHttpClient().post($config.apiBase?.() + "/" + i.id + "/starred", {
                                        starred: true,
                                    })
                                ).data
                            },
                        })
                    }
                },
            },
            {
                name: "owner",
                title: "@tasks:issue.owner",
                type: "string",
                getter: (issue: Issue) => issue.owner?.name,
            },
            {
                name: "name",
                sortField: "a.name",
                title: "@tasks:issue.name",
                type: "string",
                getter: (issue: Issue) => {
                    if (!issue.name) {
                        return
                    }
                    if (issue.type === "ComThread" && issue.nameProps?.name) {
                        return [
                            h("div", [issue.name]),
                            h(
                                "div",
                                {
                                    class: "font-slab color-primary",
                                },
                                [issue.nameProps.name],
                            ),
                        ]
                    }

                    return i18n.t(issue.name, issue.nameProps) ?? issue.name
                },
            },
            {
                name: "createdAt",
                sortField: "a.createdAt",
                title: "@tasks:issue.createdAt",
                type: "datetime",
            },
            {
                name: "lastTaskAt",
                sortField: "a.lastTaskAt",
                title: "@tasks:issue.lastTaskAt",
                type: "datetime",
            },
            {
                name: "taskCount",
                sortField: "a.messageCount",
                title: "@tasks:issue.messageCount",
                type: "vnode",
                thClass: () => "text-center",
                tdClass: () => "text-center",
                getter: (issue: Issue) =>
                    h(
                        "div",
                        {
                            class: "text-center",
                            title: i18n.t("@tasks:issue.messageCountHover", {
                                count: issue.messageCount,
                                unread: issue["unreadMessagesCount"] ?? 0,
                            }),
                        },
                        [
                            issue.messageCount +
                                (issue["unreadMessagesCount"] ?? 0 > 0
                                    ? " (" + issue["unreadMessagesCount"] + ")"
                                    : ""),
                        ],
                    ),
            },
            {
                name: "taskCount",
                sortField: "a.taskCount",
                title: "@tasks:issue.tasksCount",
                type: "vnode",
                thClass: () => "text-center",
                tdClass: () => "text-center",
                getter: (issue: Issue) =>
                    h(
                        "div",
                        {
                            class: "text-center",
                            title: i18n.t("@tasks:issue.tasksCountHover", {
                                count: issue.taskWithoutMessagesCount,
                                open: issue.openTaskWithoutMessagesCount ?? 0,
                            }),
                        },
                        [
                            issue.taskWithoutMessagesCount +
                                (issue.openTaskWithoutMessagesCount ?? 0 > 0
                                    ? " (" + issue.openTaskWithoutMessagesCount + ")"
                                    : ""),
                        ],
                    ),
            },
            {
                name: "state",
                sortField: "a.state",
                title: "@tasks:issue.state",
                type: "vnode",
                tdClass: () => "text-right",
                thClass: () => "text-right",
                getter: (issue: Issue) => {
                    if (issue.state === "done") {
                        return h("span", { title: $datetime(issue.closedAt) }, [
                            i18n.t(`@tasks:issue.issue_states.${issue.state}`),
                        ])
                    }

                    return i18n.t(`@tasks:issue.issue_states.${issue.state}`)
                },
            },
        ],
        orderField: "lastTaskAt",
        orderDirection: "desc",
        onClick: "show",
        requestParams: ref({
            viewMode: "admin",
        }),
        trClass: item => {
            return item["unreadMessagesCount"] > 0 ? "font-weight-bold" : ""
        },
    }
    $config.safeDeleteDialog = {
        active: true,
    }
    $config.afterSaveAction = "navigate-list"
    $config.apiBase = () => "tasks/issue"
    $config.mode = "in-table"
    $config.show = (item: Issue) => ({
        component: IssueShow,
        props: {
            id: item.id,
            viewMode: "admin",
            filterOnlyMessages: filterOnlyMessages?.value ?? false,
        },
    })
    $config.new = IssueNew

    return [normalizeModuleConfiguration($config), loginSearchFilter]
}
