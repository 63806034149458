<script setup lang="ts">
import RoundedButton from "@/components/RoundedButton.vue"
import VbListCustomizeFields from "@/components/crud/VbListCustomizeFields.vue"
import VbListLoginSearchFilter from "@/components/crud/VbListLoginSearchFilter.vue"
import VbListSearchFilterButton from "@/components/crud/VbListLoginSearchFilterButton.vue"
import VbListPagination from "@/components/crud/VbListPagination.vue"
import VbListSearch from "@/components/crud/VbListSearch.vue"
import VbListTable from "@/components/crud/VbListTable.vue"
import VbListToolbar from "@/components/crud/VbListToolbar.vue"
import { useLoginSearchFilter } from "@/composables/useLoginSearchFilter"
import type { LoginSearchFilterConfiguration } from "@/pages/App/Staff/config"
import { VfList, type ModuleConfiguration } from "@/vf"
import { computed, inject, ref, toRefs, type Ref } from "vue"

const props = withDefaults(
    defineProps<{
        config: ModuleConfiguration
        disableSearch?: boolean
        toolbar?: boolean
        header?: boolean
        pagination?: boolean
    }>(),
    {
        disableSearch: false,
        toolbar: true,
        header: true,
        pagination: true,
    },
)
const { config } = toRefs(props)

const openModule: Ref<false | "loginSearchFilter" | "customizeFields"> = ref(false)
const list = ref<typeof VfList>()

const loginSearchFilterConfiguration = inject<LoginSearchFilterConfiguration | undefined>(
    "login-search-filter-config",
    undefined,
)
const listId = computed(() => loginSearchFilterConfiguration?.id ?? config.value.list?.id())
const loginSearchFilter = loginSearchFilterConfiguration?.enabled ? useLoginSearchFilter() : undefined
loginSearchFilter?.init(listId.value)

defineExpose({
    refresh: () => list.value?.refresh(),
})
</script>
<template>
    <VfList
        :await="true"
        v-bind="{ config }"
        v-slot="{ listState, fields, filteredFieldGroups, moduleAction }"
        ref="list"
    >
        <div class="card">
            <div class="card-header" v-if="header">
                <slot name="header">
                    <div class="page-header">
                        <h1>{{ $t(config.readableName.plural) }}</h1>
                        <h2></h2>
                    </div>
                </slot>
            </div>
            <div class="card-toolbar" v-if="toolbar">
                <slot name="toolbar" v-bind="{ filter: listState.filter, refresh: listState.refresh, moduleAction }">
                    <VbListToolbar></VbListToolbar>
                    <VbListSearch @search="listState.refresh()" v-if="!disableSearch"></VbListSearch>

                    <RoundedButton
                        icon="fa fa-filter"
                        white
                        @click="openModule = openModule === 'loginSearchFilter' ? false : 'loginSearchFilter'"
                        :active="openModule === 'loginSearchFilter'"
                        v-if="loginSearchFilterConfiguration?.enabled"
                    >
                        {{ $t("@app:login.loginSearchFilter") }}
                    </RoundedButton>
                    <RoundedButton
                        icon="fa fa-cog"
                        white
                        @click="openModule = openModule === 'customizeFields' ? false : 'customizeFields'"
                        :active="openModule === 'customizeFields'"
                        v-if="config.list.fieldsCustomizable"
                    >
                        Anpassen
                    </RoundedButton>
                </slot>
                <slot name="toolbarFilter">
                    <div class="mt-2" v-if="loginSearchFilterConfiguration?.enabled">
                        <VbListSearchFilterButton
                            v-bind="{ filter }"
                            v-for="filter in loginSearchFilter?.getFilters(listId)"
                            :key="filter.id"
                            :class="{ 'btn-primary-dark': filter.active }"
                            @click="loginSearchFilter?.loadFilter(filter)"
                        ></VbListSearchFilterButton>
                    </div>
                </slot>
                <slot name="afterToolbar" v-bind="{ listState, filter: listState.filter }"></slot>
            </div>
            <div class="card-body p-0">
                <div v-animate-show="openModule === 'loginSearchFilter'" v-if="loginSearchFilterConfiguration?.enabled">
                    <VbListLoginSearchFilter />
                </div>
                <div v-animate-show="openModule === 'customizeFields'">
                    <VbListCustomizeFields />
                </div>
                <slot name="beforeTable" v-bind="{ listState }"></slot>
                <div class="table-responsive position-relative">
                    <VbListTable v-bind="{ config, listState, fields, filteredFieldGroups }">
                        <!-- slot pass through-->
                        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
                            <slot :name="name" v-bind="slotData" />
                        </template>
                    </VbListTable>
                </div>
                <VbListPagination @page-changed="listState.page = $event" v-if="pagination"></VbListPagination>
            </div>
        </div>
    </VfList>
</template>
